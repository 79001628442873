import React from 'react';
import PropTypes from 'prop-types';
import { cloudinaryTransforms } from '../../utils/cloudinaryTransforms';

const Pricing = ({ data }) => {
  return (
    <div className="columns">
      {data.map((price, idx) => (
        <PriceCard key={`${price.plan}-${idx}`} price={price} />
      ))}
    </div>
  );
};

const PriceCard = ({ price }) => {
  const imgTransforms = 'c_fit,q_80,w_320,h_240';
  const [showMore, setShowMore] = React.useState(false);

  let picture = cloudinaryTransforms(price.picture, imgTransforms);
  return (
    <div
      key={price.plan}
      className="column"
      style={{
        border: '1px solid #c4001d',
        background: '#fff',
        margin: '0 0.5rem',
        borderRadius: '4px',
      }}
    >
      <section style={{ padding: '1rem 0.5rem' }}>
        <h4
          style={{
            paddingBottom: '0.5rem',
            borderBottom: '1px solid #cc3b49',
          }}
          className="has-text-primary is-size-4 has-text-centered has-text-weight-semibold"
        >
          {price.plan}
        </h4>
        <h3 className="has-text-centered has-text-weight-semibold">
          {price.description}
        </h3>
        <h2
          className="has-text-centered has-text-weight-bold has-text-primary has-text-centered"
          style={{ marginTop: '0' }}
        >
          ${price.price}
        </h2>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem 0',
          }}
        >
          <img
            style={{
              border: '1px solid black',
              borderRadius: '4px',
              height: '240px',
            }}
            src={picture}
            alt=""
          />
        </div>

        {price.recycleDescription && (
          <h3 className="has-text-centered has-text-weight-semibold">
            {price.recycleDescription}
          </h3>
        )}
        {price.recycle && (
          <h2 className="has-text-centered has-text-weight-bold has-text-primary has-text-centered">
            ${price.recycle}
          </h2>
        )}
        {price.toteRentalFee && (
          <h3 className="has-text-centered has-text-weight-semibold">
            Tote Rental Fee ${price.toteRentalFee}
          </h3>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!!price.footer && !showMore ? (
            <button
              type="button"
              className="button is-white is-light is-medium is-bold"
              style={{
                color: '#cc3b49',
                fontWeight: '700',
              }}
              onClick={() => setShowMore(true)}
            >
              More Info
            </button>
          ) : !!price.footer ? (
            <p>{price.footer}</p>
          ) : null}
        </div>
      </section>
    </div>
  );
};

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      picture: PropTypes.string,
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

export default Pricing;
