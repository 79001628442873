import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PricingPageTemplate from '../components/PricingPageTemplate';
import Layout from '../components/Layout';

const PricingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PricingPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        pricing={{
          residential: frontmatter.pricing.residential,
          commercial: frontmatter.pricing.commercial,
          rolloffService: frontmatter.pricing.rolloff_service,
        }}
      />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        pricing {
          residential {
            description
            plan
            price
            picture
            toteRentalFee
            footer
          }

          commercial {
            description
            plan
            price
            recycle
            recycleDescription
            picture
          }

          rolloff_service {
            description
            plan
            price
            picture
          }
        }
      }
    }
  }
`;
