import React from 'react';
import Helmet from 'react-helmet';
import Pricing from '../Pricing';
import PropTypes from 'prop-types';

const tabs = [
  { key: 'residential', text: 'Residential' },
  { key: 'commercial', text: 'Commercial' },
  { key: 'rolloffService', text: 'Roll-Off Service' },
];

const PricingPageTemplate = ({ meta_title, meta_description, pricing }) => {
  const [activeTab, setActiveTab] = React.useState('residential');

  return (
    <div style={{ backgroundColor: '#EEEEEE', height: '100%' }}>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section className="section section--gradient">
        <div className="columns">
          <div className="column">
            <h1
              className="has-text-weight-semibold is-size-1 has-text-centered"
              style={{ marginBottom: '1rem' }}
            >
              Pricing
            </h1>

            <div className="tabs is-hidden-mobile is-medium is-toggle is-toggle-rounded is-centered">
              <ul>
                {tabs.map(({ key, text }) => (
                  <li
                    key={key}
                    className={activeTab === key ? 'is-active' : ''}
                  >
                    <a
                      type="button"
                      onClick={() => setActiveTab(key)}
                      style={
                        activeTab !== key
                          ? {
                              backgroundColor: '#fff',
                            }
                          : {}
                      }
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="tabs is-hidden-tablet is-small is-toggle is-toggle-rounded is-centered">
              <ul>
                {tabs.map(({ key, text }) => (
                  <li
                    key={key}
                    className={activeTab === key ? 'is-active' : ''}
                  >
                    <a type="button" onClick={() => setActiveTab(key)}>
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="content">
              <Pricing data={pricing[activeTab]} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    residential: PropTypes.any,
    commercial: PropTypes.any,
    rolloffService: PropTypes.any,
  }),
};

export default PricingPageTemplate;
